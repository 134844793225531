import {MinistryOptionType} from "constants/event";
import {Row} from "../divisions/type";
import {getRefMinistryByDivision} from "./client";

export enum TabKey {
    'schedule',
    'requirement',
    'form',
    'ministry_types',
    'ministry_options'
}

export enum EventStatus {
    OPEN = 'OPEN',
    CLOSE = 'CLOSE',
}

export enum FormQuestionType {
    QUESTION = 'QUESTION',
    RADIO = 'RADIO',
    CHECKBOX = 'CHECKBOX',
    FILE = 'FILE'
}

export type Question = {
    id: number;
    type: FormQuestionType;
    question: string;
    choices?: string[];
    fileCategory?: string;
    fileType?: string;
    fileSize?: string;
}

export type Event = {
    id: string;
    church_id: string;
    church_name: string;
    open_date: string;
    close_date: string;
    maximum_approval_date: string;
    is_assessment_broadcasted: boolean;
    status: string;
}

// list of events (overview)
export type EventSchedule = {
    churchId: string;
    churchName: string;
    closeDate: string;
    openDate: string;
    maximumApprovalDate: string;
}

export type EventRequirement = {
    nijRequirement: boolean;
    msjRequirement: number;
    baptismRequirement: boolean;
    isAssessmentBroadcasted: boolean;
    termsAndConditions: string;
    isHolySpiritBaptismRequired: boolean;
}

export type EventMinistryType = {
    servolutionTypeId: number;
    firstOptionSecondOptionType?: MinistryOptionType;
}

export type EventDetail = {
    id: string;
    churchId: string;
    churchName: string;
    servolutionTypeId: number;
    firstOptionMinistries: string[];
    secondOptionMinistries: string[];
    termsAndConditions: string;
    firstOptionSecondOptionType: number;
    eventsQuestionsForm: Question[];

    status: string | null;
    createdAt: string;
    updatedAt: string;
    deletedAt: string | null;
} & EventSchedule & EventRequirement & EventMinistryType;

export type Churches = {
    id: string; // Updated to string to match the API
    type: string;
    name: string;
    status: string;
    city: {
        name: string;
        city_id: number;
        geometry?: {
            lat: number;
            lng: number;
        };
        gmtOffset: number;
        timezone_abbreviation: string;
    };
    services: {
        name: string;
        alias?: string;
        service_id: string;
        servicecode: string;
    }[] | null;
    country: {
        name: string;
        country_id: string;
    };
    cityCode: string;
    churchcode: string;
    oldId: number | null;
    isDeleted: boolean;
    updatedAt: string;
    createdAt: string;
    parentCouchdbId?: string; // Optional since it's not present in the API example
    code?: string;           // Optional since it's not present in the API example
    regionId: string;        // Updated to string to match the API
    canFollowup: boolean;
}

export type DivisionResponse = {
    rows: any;
    data: {
        totalRecords: number;
        rows?: Division[];
    };
    status: number;
    statusText: string;
    headers: {
        "content-type": string;
    };
    config: {
        transitional: {
            silentJSONParsing: boolean;
            forcedJSONParsing: boolean;
            clarifyTimeoutError: boolean;
        };
        adapter: string[];
        transformRequest: (null | Function)[];
        transformResponse: (null | Function)[];
        timeout: number;
        xsrfCookieName: string;
        xsrfHeaderName: string;
        maxContentLength: number;
        maxBodyLength: number;
        env: Record<string, unknown>;
        headers: {
            Accept: string;
            Authorization: string;
        };
        url: string;
        method: string;
    };
    request: Record<string, unknown>;
};

export type MinistriesResponse = {
    data: {
        totalRecords: number;
        rows: Ministry[];
    }
};

export type MinistryByDivision = {
    id: string;
    name: string;
    description: string;
    createdAt: string;
    updatedAt: string;
    departmentId: string;
    ministryTypeId: number;
    department: Department;
    ministryType: MinistryType;
};


export type Division = {
    id: string;
    name: string;
    description: string;
    serviceType: string;
    createdAt: string;
    updatedAt: string;
    ministryId: string;
    ministryTypeId: number;
    ministries?: ministryDetail
    isEagleKidz: boolean;
};

export type ministryDetail = {
    id: string;
    name: string;
    createdAt: string;
    departmentId: string;
    ministryTypeId: number;
    updatedAt?: string;
    description?: string;
    ministryType?: {
        id: number;
        name: string;
        createdAt: string;
    };
}

export type Ministry = {
    id: string; // Updated to match the string type in the object
    name: string; // Added to match the "name" property
    createdAt: string; // Matches the ISO date string format
    departmentId: string; // Matches the "departmentId" field
    ministryTypeId: number; // Matches the "ministryTypeId" field
    department: Department; // Nested Department object
    ministryType: MinistryType; // Nested MinistryType object
};

export type Department = {
    id: string; // Matches the string type for department ID
    name: string; // Matches the "name" field
    iconUrl: string; // Matches the "iconUrl" field
    createdAt: string; // Matches the ISO date string format
    updatedAt: string; // Matches the ISO date string format
    isDeleted: boolean; // Matches the "isDeleted" boolean field
};

export type MinistryType = {
    id: number; // Matches the number type for ministry type ID
    name: string; // Matches the "name" field
    createdAt: string; // Matches the ISO date string format
};

export type MinistryOptions = {
    id: string;
    name: string;
    description?: string; // Optional, since not all ministries may have it
    createdAt: string;
    updatedAt?: string; // Optional, some records may not have `updatedAt`
    deletedAt?: string; // Optional
    departmentId: string;
    ministryTypeId: number;
    department?: {
        id: string;
        name: string;
        iconUrl?: string;
        createdAt: string;
        updatedAt?: string;
        isDeleted?: boolean;
    };
    ministryType?: {
        id: number;
        name: string;
        createdAt: string;
    };
};