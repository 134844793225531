import React, {FormEvent, useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {useEvent} from 'datas/event/store';
import {EventRequirement, TabKey} from 'datas/event/type';
import {FormCheck} from 'components/atoms/Form/Radio/component';
import {
    defaultEventRequirementTnC,
    isAssessmentBroadcasted,
    isBaptismRequired,
    isHolySpiritBaptismRequired,
    isNIJRequired,
    msjRequired
} from "constants/event";
import 'react-quill/dist/quill.core.css'
import 'react-quill/dist/quill.bubble.css'
import 'highlight.js/styles/dark.css'
import 'react-quill/dist/quill.snow.css';
import hljs from 'highlight.js';
import ReactQuill from 'react-quill';
import * as ReactQuillProperties from 'constants/react-quill';
import './style.scss';

hljs.configure({
    languages: ['javascript', 'ruby', 'python', 'rust'],
});

export const FormEventRequirement = () => {
    const {setActiveTab, dataRequirement, setDataRequirementByKey} = useEvent();
    const location = useLocation();
    const [validated, setValidated] = useState(false);

    const onPrevPage = () => {
        setActiveTab(TabKey.schedule);
    }

    const onNextPage = () => {
        setActiveTab(TabKey.form);
    }

    const onChange = (key: keyof EventRequirement, value: any) => {
        if (key === 'msjRequirement') {
            setDataRequirementByKey(key, parseInt(value));
        } else {
            setDataRequirementByKey(key, value);
        }
    }

    const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();

        try {
            const form = e.currentTarget;
            if (!form.checkValidity()) {
                setValidated(true);
                return;
            }

            onNextPage();
        } catch (err: any) {

        } finally {
            // setValidated(false);
        }
    }

    useEffect(() => {
        if (!location?.state?.eventId && !dataRequirement?.termsAndConditions) {
            onChange('termsAndConditions', defaultEventRequirementTnC);
        }
    }, [])

    return (
        <Form
            noValidate
            validated={validated}
            className='form_event_requirements'
            onSubmit={onSubmit}
        >
            <div className='form_event_requirements_header'>
                <h2>General Servolution Requirement</h2>
            </div>
            <div className='form_event_requirements_content'>
                <div className='d-flex gap-3'>
                    <Form.Group>
                        <Form.Label>NIJ Required ?</Form.Label>
                        <FormCheck
                            required
                            value={dataRequirement.nijRequirement !== undefined
                                ? dataRequirement.nijRequirement ? '1' : '0'
                                : ''
                            }
                            options={isNIJRequired}
                            onChange={(e) => onChange('nijRequirement', e === '1')}
                        />
                        <Form.Control.Feedback type="invalid">
                            Pertanyaan wajib diisi
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Baptism Required ?</Form.Label>
                        <FormCheck
                            required
                            value={dataRequirement.baptismRequirement !== undefined
                                ? dataRequirement.baptismRequirement ? '1' : '0'
                                : ''
                            }
                            options={isBaptismRequired}
                            onChange={(e) => onChange('baptismRequirement', e === '1')}
                        />
                        <Form.Control.Feedback type="invalid">
                            Pertanyaan wajib diisi
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Is Assessment Broadcasted?</Form.Label>
                        <FormCheck
                            required
                            value={dataRequirement.isAssessmentBroadcasted !== undefined
                                ? dataRequirement.isAssessmentBroadcasted ? '1' : '0'
                                : ''
                            }
                            options={isAssessmentBroadcasted}
                            onChange={(e) => onChange('isAssessmentBroadcasted', e === '1')}
                        />
                        <Form.Control.Feedback type="invalid">
                            Pertanyaan wajib diisi
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Is Holy Spirit Baptism Required?</Form.Label>
                        <FormCheck
                            required
                            value={dataRequirement.isHolySpiritBaptismRequired !== undefined
                                ? dataRequirement.isHolySpiritBaptismRequired ? '1' : '0'
                                : ''
                            }
                            options={isHolySpiritBaptismRequired}
                            onChange={(e) => onChange('isHolySpiritBaptismRequired', e === '1')}
                        />
                        <Form.Control.Feedback type="invalid">
                            Pertanyaan wajib diisi
                        </Form.Control.Feedback>
                    </Form.Group>
                </div>

                <Form.Group>
                    <Form.Label>MSJ Required ?</Form.Label>
                    <Form.Select
                        required
                        aria-label="MSJ Required?"
                        onChange={(e) => onChange('msjRequirement', e.target.value)}
                        value={dataRequirement.msjRequirement !== undefined
                            ? dataRequirement.msjRequirement.toString()
                            : ''
                        }
                    >
                        <option hidden value=''>Pilih kategori</option>
                        {msjRequired.map(option => (
                            <option value={option.value} key={`option_${option.value}`}>{option.label}</option>
                        ))}
                    </Form.Select>
                    <Form.Control.Feedback type='invalid'>
                        Pertanyaan wajib diisi
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group>
                    <Form.Label>Term and Conditions</Form.Label>
                    <ReactQuill
                        value={dataRequirement.termsAndConditions}
                        onChange={value => onChange('termsAndConditions', value)}
                        modules={ReactQuillProperties.modules}
                        formats={ReactQuillProperties.formats}
                        theme="snow"
                        className='form_event_requirements_editor'
                    />

                    <div className='form_event_requirements_validate'>
                        <Form.Control
                            required
                            value={dataRequirement.termsAndConditions}
                            onChange={() => {
                            }}
                        />

                        <Form.Control.Feedback type='invalid'>
                            Pertanyaan wajib diisi
                        </Form.Control.Feedback>
                    </div>
                </Form.Group>

                <hr className='my-0'/>

                <div className='w-100 d-flex justify-content-end gap-1'>
                    <Button variant='secondary' onClick={onPrevPage}>Previous</Button>
                    <Button type='submit' className='d-flex align-items-center gap-1 text-light'>
                        Next <span className='material-symbols-outlined'>keyboard_arrow_right</span>
                    </Button>
                </div>
            </div>
        </Form>
    )
}
