import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import ModalEditDivision from 'components/molecules/Modal/ModalEditDivision';
import {ministryDetail, TabKey} from "datas/event/type";
import {useEvent} from "datas/event/store";
import {getRefMinistryOptions, patchEvent, postEvent} from 'datas/event/client';
import {ministryDefaultRef, MinistryOptionCategory, ministryOptionCategoryRef} from 'constants/event';
import {showToast} from 'utils/toast';
import {parseISO, formatISO} from "date-fns";
import './style.scss';

export const FormMinistryOptions = () => {
    const {
        setActiveTab,
        dataSchedule,
        dataRequirement,
        dataQuestion,
        dataMinistryType,
        dataMinistryFirstOptions,
        dataMinistrySecondOptions,
        dataMinistryOptionRef,
        setOpenedDivisionsId,
        setDataMinistryOptionRef,
        setDataDivisionOptionRef,
        dataDivisionOptionRef
    } = useEvent();

    const location = useLocation();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [activeCategoryTab, setActiveCategoryTab] = useState<MinistryOptionCategory>(MinistryOptionCategory.FirstOptions);


    const onLoadDataRefDivision = async () => {
        const data = await getRefMinistryOptions({typeId: dataMinistryType.servolutionTypeId});
        if (data?.length) {
            setDataDivisionOptionRef(data);
        }
    }

    const onToggleCategoryTab = (tabId: MinistryOptionCategory) => {
        setActiveCategoryTab(tabId);
    }

    const onPrevPage = () => {
        setActiveTab(TabKey.ministry_types);
    }

    const onSubmit = async () => {
        try {
            setIsLoading(true);
            const payload = {
                churchId: dataSchedule.churchId,
                churchName: dataSchedule.churchName,
                closeDate: formatISO(parseISO(dataSchedule.closeDate), {representation: "complete"}),
                openDate: formatISO(parseISO(dataSchedule.openDate), {representation: "complete"}),
                maximumApprovalDate: formatISO(parseISO(dataSchedule.maximumApprovalDate), {representation: "complete"}),
                ...dataRequirement,
                ...dataMinistryType,
                eventsQuestionsForm: dataQuestion,
                firstOptionMinistries: '{ ' + dataMinistryFirstOptions.toString() + ' }',
                secondOptionMinistries: '{ ' + dataMinistrySecondOptions.toString() + ' }',
            }

            if (location?.state?.eventId) {
                await patchEvent(location.state.eventId, payload);
            } else {
                await postEvent(payload);
            }

            showToast('success', 'Event Data Successfully Saved');
            navigate('/events');
        } catch (err) {
            showToast('error', 'Event Data Save Failed. Please try again or contact us for assistance.');
        } finally {
            setIsLoading(false);
        }
    }

    const onOpenDivisionsModal = (divisionsId: string, ministries: ministryDetail) => {
        setOpenedDivisionsId({
            divisionsId,
            optionCategory: activeCategoryTab,
            ministries
        });
    }

    useEffect(() => {
        onLoadDataRefDivision();
    }, [])

    return (
        <div className='form_event_ministry_option'>
            <div className='form_event_ministry_option_header'>
                <h2>Ministry Options</h2>
            </div>

            <div className='form_event_ministry_option_content'>

                {dataMinistryType.servolutionTypeId === 1 && (
                    <div className='form_event_ministry_option_content_header'>
                        {ministryOptionCategoryRef.map(category => (
                            <button
                                key={`ministry_option_category_${category.value}`}
                                onClick={() => onToggleCategoryTab(category.value)}
                                className={category.value === activeCategoryTab ? 'active' : ''}
                            >
                                {category.label}
                            </button>
                        ))}
                    </div>
                )}

                {dataDivisionOptionRef ? (
                    <div className='form_event_ministry_option_content_list'>
                        <Accordion defaultActiveKey={['0']} alwaysOpen className="w-100">
                            {dataDivisionOptionRef
                                ?.map((option, index) => {
                                    console.log(option)
                                    // Determine which ministry options to use based on active category
                                    const dataMinistryOptions =
                                        activeCategoryTab === MinistryOptionCategory.FirstOptions
                                            ? dataMinistryFirstOptions
                                            : dataMinistrySecondOptions;

                                    // Assuming divisions aren't part of the data, we use departmentId for filtering
                                    const selectedDepartment = dataMinistryOptions?.includes(option.id);

                                    return (
                                        <Accordion.Item
                                            eventKey={index.toString()}
                                            as="div"
                                            key={`form_event_ministry_option_${option.id}`}
                                        >
                                            <Accordion.Header>
                                                {option.ministries?.id &&
                                                    (
                                                        <div
                                                            className="d-flex align-items-center justify-content-between w-100 pe-3">
                                                            <p className="mb-0">{option.name}</p>
                                                            <span
                                                                className={`btn btn-primary text-light ${isLoading && 'disabled'}`}
                                                                onClick={() => onOpenDivisionsModal(option.id ?? "000", option.ministries ?? ministryDefaultRef)}
                                                                role="button"
                                                                aria-disabled={isLoading}
                                                            >
                                                                {selectedDepartment ? 'Edit Department' : 'Add Department'}
                                                            </span>
                                                        </div>
                                                    )
                                                }
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div className="d-flex gap-1 flex-wrap">
                                                    {selectedDepartment ? (
                                                        <span
                                                            className="btn btn-sm btn-primary text-light"
                                                            key={`form_event_ministry_department_${option.id}`}
                                                        >
                                                            {option?.ministries?.description ?? "No Divission name"}
                                                        </span>
                                                    ) : (
                                                        <p className="mb-0 text-muted">
                                                            Add the ministry options that the users will choose.
                                                        </p>
                                                    )}
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    );
                                })}
                        </Accordion>
                    </div>
                ) : (
                    <div
                        className='w-100 d-flex align-items-center justify-content-center'
                        style={{height: '20.5rem'}}
                    >
                        <Spinner/>
                    </div>
                )}

                <div className='w-100 d-flex justify-content-end gap-1'>
                    <Button
                        variant='secondary'
                        onClick={onPrevPage}
                        disabled={isLoading}
                    >
                        Previous
                    </Button>
                    <Button
                        className='d-flex align-items-center gap-1 text-light'
                        onClick={onSubmit}
                        disabled={isLoading}
                    >
                        {isLoading && (
                            <Spinner size='sm' variant='light' className='me-1'/>
                        )}
                        Submit <span className='material-symbols-outlined'>keyboard_arrow_right</span>
                    </Button>
                </div>
            </div>

            <ModalEditDivision/>
        </div>
    );
};