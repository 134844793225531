import {create} from "zustand";
import {
    Division,
    Event,
    EventMinistryType,
    EventRequirement,
    EventSchedule,
    ministryDetail,
    MinistryOptions,
    Question,
    TabKey
} from "./type";
import {MinistryOptionCategory} from "constants/event";

type EventStore = {
    isLoading: boolean;
    setIsLoading: (loading: boolean) => void;

    messageLoading: string;
    setMessageLoading: (message: string) => void;

    activeTab: TabKey;
    setActiveTab: (tab: TabKey) => void;

    dataEvent: Event[] | null;
    setDataEvent: (data: Event[]) => void;

    dataSchedule: EventSchedule;
    setDataSchedule: (data: EventSchedule) => void;
    setDataScheduleByKey: (key: keyof EventSchedule, value: string) => void;

    dataRequirement: EventRequirement;
    setDataRequirement: (data: EventRequirement) => void;
    setDataRequirementByKey: (key: keyof EventRequirement, value: string | number) => void;

    dataQuestion: Question[];
    setDataQuestion: (dataQuestion: Question[] | ((prevDataQuestion: Question[]) => Question[])) => void;
    setDataQuestionByKey: (rowIndex: number, question: Question) => void;

    dataMinistryType: EventMinistryType;
    setDataMinistryType: (data: EventMinistryType | ((prevData: EventMinistryType) => EventMinistryType)) => void;

    openedDivisionsId?: {
        divisionsId: string,
        optionCategory: MinistryOptionCategory,
        ministries: ministryDetail
    };
    setOpenedDivisionsId: (division?: { divisionsId: string, optionCategory: MinistryOptionCategory,  ministries: ministryDetail}) => void;

    dataMinistryFirstOptions: string[];
    setDataMinistryFirstOptions: (data: string[]) => void;

    dataMinistrySecondOptions: string[];
    setDataMinistrySecondOptions: (data: string[]) => void;

    dataMinistryOptionRef: MinistryOptions[];
    setDataMinistryOptionRef: (data: MinistryOptions[] | ((prevData: MinistryOptions[]) => MinistryOptions[])) => void;

    dataDivisionOptionRef: Division[];
    setDataDivisionOptionRef: (data: Division[] | ((prevData: Division[]) => Division[])) => void;

    resetFormData: () => void;
}

export const useEvent = create<EventStore>((set) => ({
    isLoading: false,
    setIsLoading: (loading) => set(() => ({
        isLoading: loading,
    })),

    messageLoading: '',
    setMessageLoading: (message) => set(() => ({
        messageLoading: message,
    })),

    activeTab: TabKey.schedule,
    setActiveTab: (tab) => set(() => ({
        activeTab: tab,
    })),

    dataEvent: null,
    setDataEvent: (data) => set(() => ({
        dataEvent: data,
    })),

    dataSchedule: {} as EventSchedule,
    setDataSchedule: data => set(() => ({
        dataSchedule: data,
    })),
    setDataScheduleByKey: (key, value) => set(prev => ({
        dataSchedule: {
            ...prev.dataSchedule,
            [key]: value,
        }
    })),

    dataRequirement: {} as EventRequirement,
    setDataRequirement: data => set(() => ({
        dataRequirement: data,
    })),
    setDataRequirementByKey: (key, value) => set(prev => ({
        dataRequirement: {
            ...prev.dataRequirement,
            [key]: value,
        }
    })),

    dataQuestion: [],
    setDataQuestion: data => set(state => ({
        dataQuestion: typeof data === 'function' ? data(state.dataQuestion) : data,
    })),
    setDataQuestionByKey: (rowIndex, question) => set(prev => {
        const questions = [...prev.dataQuestion];
        questions.splice(rowIndex, 1, question);
        return {
            dataQuestion: questions
        }
    }),

    dataMinistryType: {} as EventMinistryType,
    setDataMinistryType: data => set(state => ({
        dataMinistryType: typeof data === 'function' ? data(state.dataMinistryType) : data,
    })),

    openedDivisionsId: undefined,
    setOpenedDivisionsId: (division) => set(() => ({
        openedDivisionsId: division,
    })),

    dataMinistryFirstOptions: [],
    setDataMinistryFirstOptions: data => set(() => ({
        dataMinistryFirstOptions: data,
    })),

    dataMinistrySecondOptions: [],
    setDataMinistrySecondOptions: data => set(() => ({
        dataMinistrySecondOptions: data,
    })),

    dataMinistryOptionRef: [],
    setDataMinistryOptionRef: data => set(state => ({
        dataMinistryOptionRef: typeof data === 'function' ? data(state.dataMinistryOptionRef) : data,
    })),

    dataDivisionOptionRef: [],
    setDataDivisionOptionRef: data => set(state => ({
        dataDivisionOptionRef: typeof data === 'function' ? data(state.dataDivisionOptionRef) : data,
    })),

    resetFormData: () => set(() => ({
        activeTab: TabKey.schedule,
        dataSchedule: {} as EventSchedule,
        dataRequirement: {} as EventRequirement,
        dataQuestion: [],
        dataMinistryType: {} as EventMinistryType,
        dataMinistryOptions: [[], [], []],
        dataMinistryOptionRef: [],
    }))
}));
